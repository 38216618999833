<template>
  <div class="pageMain">
    <!-- 企业备案 -->
    <div class="content">
      <!-- 左边列表 -->
      <CustomTables
        :data="tableData"
        height="55vh"
        :titleIcon="true"
        :total="total"
        class="tableHear"
        :headerCellStyle="headerCellStyle"
        @handleCurrentChange="handleCurrentChange"
    @handleSizeChange="handleSizeChange"
        @handleCurrentChanges="handleCurrentChangesProject"
      >
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            :infoShow="true"
            info="项目名称"
            :iconShow="true"
            
          >
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="" class="formItem">
              <el-input
                v-model="searchForm.projName"
                @input="change"
                size="small"
                placeholder="请输入项目名称"
              ></el-input>
            </el-form-item>
          </el-form>
          </SearchLeft>
        </template>
        <template slot="title">
          <div class="title">今日报警({{ 506 }})</div>
        </template>
        <el-table-column type="index" label="排序" width="50" align="center">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" align="center"> </el-table-column>
        <el-table-column prop="deviceCount" label="总数（台）" align="center"> </el-table-column>
      </CustomTables>
      <div class="tableBox">
      
      <!--右边列表  -->
    <div class="form">
      <SearchHead
        @search="search2"
        @reset="reset2"
        :isExport="true"
        @tableExport="tableExport"
      >
        <el-form :inline="true" :model="searchForm2">
          
          <el-form-item label="姓名">
            <el-input
              v-model="searchForm2.operName"
              size="small"
              placeholder="姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否过期">
            <el-select
              v-model="searchForm2.expires"
              size="small"
              placeholder="是否过期"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input
              v-model="searchForm2.operMobile"
              size="small"
              placeholder="联系方式"
            ></el-input>
          </el-form-item>
         
        </el-form>
      </SearchHead>
      <CustomTables
        :data="tableData2"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        height="55vh"
        :total="total2"
        title="驾驶员信息"
      >
      
        <!-- <template slot="btns">
          <el-button type="success" size="small" @click="addFrom"
          icon="el-icon-plus"  class="btn">新建</el-button
          >
        </template> -->
      
        <el-table-column type="selection" align="center" width="55">
    </el-table-column>
        <el-table-column prop="operFaceUrl" label="司机照片"  align="center"> 
          <template slot-scope="scope">
            <img :src="scope.row.operFaceUrl" alt="" style="width: 50px;height: 50px;">
          </template>
        </el-table-column>
        <el-table-column prop="operName" label="司机姓名"  align="center"> </el-table-column>
        <el-table-column prop="operSex" label="性别"  align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.operSex==0"> 男</span>
            <span v-if="scope.row.operSex==1">女</span>
          </template>
           </el-table-column>
        <el-table-column prop="operMobile" label="联系方式"  align="center"> </el-table-column>
        <el-table-column prop="startTime" label="驾驶证颁发时间"  align="center"> </el-table-column>
        <el-table-column prop="cardNo" label="驾驶证编号"  align="center"> </el-table-column>
        <el-table-column prop="endTime" label="驾驶证结束时间"  align="center"> </el-table-column>
        <el-table-column prop="expires" label="是否过期"  align="center"> 
          <template slot-scope="scope">
            <span v-if="scope.row.expires==0">未过期</span>
            <span v-if="scope.row.expires==1">过期</span>
          </template>
        </el-table-column>
      </CustomTables>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import monitor from "../../../api/modules/monitor";
export default {
  components: { CustomTables, SearchHead,SearchLeft },

  name: "monitorTowerDriver",
  data() {
    return {
      tableData: [
        
      ],
      tableData2: [
        
      ],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
      pageNum: 1,
      pageSize: 10,
    },
    searchForm2: {
      pageNum: 1,
      pageSize: 10,
    },
      options: [
    {label:'未过期',value:0},
    {label:'过期',value:1},
    ],
      total:0,
      total2:0,
      orgaId:'',
      projId:''
    };
  },
  mounted() {
    this.orgaId  =this.$route.query.item
  console.log('this.orgaId',this.orgaId);
  
    
    this.getProject()
  },
  methods: {
    getProject() {
    this.searchForm.orgaId =  this.orgaId;
    console.log('this.searchForm',this.searchForm);
    
    monitor.getProjectDeviceCountTower(this.searchForm).then((res) => {
      this.total = res.data.total;
      this.tableData = res.data.records;
      this.projId = this.tableData[0].projId
      if( this.tableData.length>0){
        this.getPage();
      }else{
      
      }
     
    });
  },
  change(e){
      console.log('e',e);
      this.searchForm.projName=e
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getProject(this.searchForm)
      
    },
  getPage(){
    let params= {
      pageNum:this.searchForm2.pageNum,
      pageSize:this.searchForm2.pageSize,
      projId:this.projId,
      operName:this.searchForm2.operName,
      operMobile:this.searchForm2.operMobile,
      expires:this.searchForm2.expires
    }
    monitor.getTowergetPage(params).then((res) => {
      this.total2 = res.data.total;
      this.tableData2 = res.data.records;
    });

  },
  search2() {
    console.log("searchForm", this.searchForm2);
      this.searchForm2.pageNum = 1;
      this.searchForm2.pageSize = 10;
      this.getPage( this.searchForm2)
    },
    search(){
      //  this.searchForm.pageNum = 1;
      // this.searchForm.pageSize = 10;
      // this.getPage( this.searchForm2)
    },
    reset() {
      this.searchForm = {};
    },
    reset2(){
   
      
      this.searchForm2 = {};
      this.getPage( this.searchForm2)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    tableExport(){},
 
    handleSelectionChange() {},
    handleSelectionChanges() {},
    handleCurrentChange(pageIndex) {
    this.searchForm.currPage = pageIndex

    this.getProject()
  },
  handleSizeChange(pageSize) {
    this.searchForm.pageSize = pageSize
    this.searchForm.currPage = 1
    this.getProject()
  },
  handleCurrentChangesProject(row){
      console.log('row',row);
      this.projId=row.projId
      this.getPage(row.projId)
      
    },
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
}
.tableBox {
  // margin-left: 10px;
  width: 74%;
}
.tableHear {
  width: 25%;
  // background:#e4ebf1
}
.title {
  margin-bottom: 10px;
color: #000;
}

.formItem{
  margin-bottom: 0px;
}
</style>
